import type { Dictionary } from "lodash";
import type { SensitiveValue } from "./propertyValueResource";
import type { ResourceWithId } from "./resource";
export interface AuditStreamConfigurationResource extends ResourceWithId<{
    Self: string;
}> {
    StreamConfigurationResource: StreamConfigurationResource | null;
    Description: string | null;
    Active: boolean;
}
export interface OpenTelemetryAuditStreamConfigurationResource {
    OpenTelemetryEndpoint: string;
    OpenTelemetryProtocol: OtlpProtocol;
    OpenTelemetryHeaders: Dictionary<string>;
    OpenTelemetrySecret: SensitiveValue;
}
export enum OtlpProtocol {
    Grpc = "Grpc",
    HttpProtobuf = "HttpProtobuf"
}
export interface SplunkAuditStreamConfigurationResource {
    SplunkEndpoint: string;
    SplunkEventCollectorToken: SensitiveValue;
}
export interface SumoLogicAuditStreamConfigurationResource {
    SumoLogicEndpoint: SensitiveValue;
}
export type StreamConfigurationResource = OpenTelemetryAuditStreamConfigurationResource | SplunkAuditStreamConfigurationResource | SumoLogicAuditStreamConfigurationResource;
