import type { PersistenceSettings } from "./projectResource";
import { HasGitPersistenceSettings } from "./projectResource";
export type GitCommit = string;
export type GitNamedRef = string;
export type GitBranch = string;
export type GitTag = string;
export type GitRef = GitCommit | GitBranch | GitTag | GitNamedRef;
export enum GitRefType {
    Commit = "Commit",
    Branch = "Branch",
    Tag = "Tag",
    Unknown = "Unknown",
    Named = "Named"
}
const refPrefix = "refs/";
const refBranchPrefix = `${refPrefix}heads/`;
const refTagPrefix = `${refPrefix}tags/`;
export function getGitRefType(ref: GitRef): GitRefType {
    if (isGitBranch(ref)) {
        return GitRefType.Branch;
    }
    else if (isGitTag(ref)) {
        return GitRefType.Tag;
    }
    else if (isGitCommit(ref)) {
        return GitRefType.Commit;
    }
    else if (isGitNamedRef(ref)) {
        return GitRefType.Named;
    }
    return GitRefType.Unknown;
}
export function getGitRefDescription(ref: GitRef | undefined) {
    if (isGitBranch(ref)) {
        return "branch";
    }
    else if (isGitTag(ref)) {
        return "tag";
    }
    else if (isGitCommit(ref)) {
        return "commit";
    }
    else if (isGitNamedRef(ref)) {
        return "named reference";
    }
    return "unknown";
}
export function isGitBranch(ref: GitRef | string | undefined | null): ref is GitBranch {
    return !!ref && ref.startsWith(refBranchPrefix);
}
export function isGitTag(ref: GitRef | string | undefined | null): ref is GitTag {
    return !!ref && ref.startsWith(refTagPrefix);
}
export function isGitCommit(ref: GitRef | string | undefined | null): ref is GitCommit {
    return !!ref && /^[0-9a-fA-F]{7,40}$/i.test(ref);
}
export function isGitNamedRef(ref: GitRef | string | undefined | null): ref is GitNamedRef {
    return !!ref && ref.startsWith(refPrefix);
}
export function toGitBranchShort(branch: GitBranch) {
    return branch.substr(refBranchPrefix.length);
}
export function toGitTagShort(tag: GitTag) {
    return tag.substr(refTagPrefix.length);
}
export function toGitNamedRefShort(ref: GitNamedRef) {
    return ref.substr(refPrefix.length);
}
export function toGitCommitShort(commit: GitCommit) {
    return commit.substring(0, 7);
}
export function toGitRefShort(ref?: GitRef) {
    if (!ref) {
        return null;
    }
    if (isGitBranch(ref))
        return toGitBranchShort(ref);
    if (isGitTag(ref))
        return toGitTagShort(ref);
    if (isGitCommit(ref))
        return toGitCommitShort(ref);
    if (isGitNamedRef(ref))
        return toGitNamedRefShort(ref);
    // Unable to determine the type based on prefix.
    // It may already be short so just return as-is
    return ref;
}
export function toGitBranch(ref: GitRef | string): GitBranch {
    if (isGitBranch(ref)) {
        return ref;
    }
    return `${refBranchPrefix}${ref}`;
}
export function toGitTag(ref: GitRef | string): GitTag {
    if (isGitTag(ref)) {
        return ref;
    }
    return `${refTagPrefix}${ref}`;
}
export function toGitBranchWhenUnknown(ref?: GitRef | string): string | null {
    if (!ref) {
        return null;
    }
    if (getGitRefType(ref) === GitRefType.Unknown) {
        return toGitBranch(ref);
    }
    return ref;
}
export interface GitReference {
    GitRef?: GitRef;
    GitCommit?: GitCommit;
}
export interface SnapshotGitReference extends GitReference {
    VariablesGitCommit?: GitCommit;
}
export interface IHaveGitReference {
    VersionControlReference: GitReference;
}
export interface IHaveSnapshotGitReference {
    VersionControlReference?: SnapshotGitReference; // Releases
    GitReference?: SnapshotGitReference; // Runbooks
}
export function IsDefaultBranch(persistenceSettings: PersistenceSettings, gitRef: GitRef | string | undefined | null): boolean | undefined {
    if (!persistenceSettings) {
        return undefined;
    }
    if (gitRef === null || gitRef === undefined) {
        return undefined;
    }
    if (HasGitPersistenceSettings(persistenceSettings)) {
        return BranchesMatch(persistenceSettings.DefaultBranch, gitRef);
    }
    return undefined;
}
export function BranchesMatch(gitRef1: GitRef | string | undefined | null, gitRef2: GitRef | string | undefined | null): boolean {
    if (gitRef1 === null || gitRef1 === undefined || gitRef2 === null || gitRef2 === undefined) {
        return false;
    }
    return toGitBranch(gitRef1) == toGitBranch(gitRef2);
}
// This value is the "default default" branch
//
// we had the magic string "master" showing up in a few places, which is not ideal.
// we need a "default default" to decide what will show up in the ui as a convenience when
// no other value has been selected, e.g: when choosing what your default branch will be
// in project version control settings.
//
// If possible, using this const should be avoided in favour of:
// - VersionControlSettings.DefaultBranch
// - ProjectContextState.branch
// whichever suits the context.
//
// TODO: decide if we want the user to be able to configure this at a server-wide level.
export const branchToShowByDefault = toGitBranch("main");
export const basePathToShowByDefault = `.octopus`;
