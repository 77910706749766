import type { Dictionary } from "lodash";
import type { Client, RequestContext } from "../client";
import type { BlueprintBranchHead, BlueprintResource, DeleteBlueprintCommand, ModifyBlueprintCommand, NewProcessBlueprint } from "../resources";
export class BlueprintRepository {
    protected readonly client: Client;
    protected readonly context: RequestContext;
    constructor(client: Client, requestContext: RequestContext) {
        this.client = client;
        this.context = requestContext;
    }
    all() {
        return this.client.get<BlueprintResource[]>(this.context, "~/bff/spaces/{spaceId}/blueprints/all");
    }
    get(id: string) {
        return this.client.get<BlueprintResource>(this.context, "~/bff/spaces/{spaceId}/blueprints/{id}", { id });
    }
    del(blueprint: DeleteBlueprintCommand) {
        return this.client.post(this.context, "~/bff/spaces/{spaceId}/blueprints/delete-by-id", blueprint);
    }
    add(blueprint: NewProcessBlueprint) {
        return this.client.post<BlueprintResource>(this.context, "~/bff/spaces/{spaceId}/blueprints", blueprint);
    }
    getBranchHead(projectId: string, branch: string) {
        return this.client.get<BlueprintBranchHead>(this.context, "~/bff/spaces/{spaceId}/blueprints/heads{?projectId,branch}", { projectId, branch });
    }
    modify(blueprint: ModifyBlueprintCommand) {
        return this.client.put<BlueprintResource>(this.context, "~/bff/spaces/{spaceId}/blueprints/{blueprintId}", blueprint, { blueprintId: blueprint.Id });
    }
    getPaginatedTemplates(processTemplatesPageFilter: GetProcessTemplatesBffRequest): Promise<GetProcessTemplatesBffResponse> {
        return this.client.get<GetProcessTemplatesBffResponse>(this.context, "~/bff/processtemplates{?skip,take,partialName}", processTemplatesPageFilter);
    }
}
export type GetProcessTemplatesBffRequest = {
    skip: number;
    take: number;
    partialName?: string;
};
export interface GetProcessTemplatesBffResponse {
    ProcessTemplates: BlueprintResource[];
    TotalResults: number;
    ItemsPerPage: number;
    NumberOfPages: number;
    ProjectToSpaceMap: Dictionary<string>;
    TotalNoOfProcessTemplates: number;
}
