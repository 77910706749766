import type { Client, RequestContext } from "../client";
import type { NewWorkerPoolResource, ResourceCollection, WorkerMachineResource, WorkerPoolResource, WorkerPoolsSummaryResource } from "../resources";
import type { DynamicWorkerTypes, WorkerPoolsSupportedTypes } from "../resources/workerPoolsSupportedTypesResouce";
import { BasicRepository } from "./basicRepository";
export class WorkerPoolsRepository extends BasicRepository<WorkerPoolResource, NewWorkerPoolResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("WorkerPools", client, requestContext);
    }
    machines(workerPool: WorkerPoolResource, args?: Partial<WorkerPoolsMachinesArgs>): Promise<ResourceCollection<WorkerMachineResource>> {
        return this.client.get<ResourceCollection<WorkerMachineResource>>(this.requestContext, workerPool.Links["Workers"], args);
    }
    summary(args?: Partial<WorkerPoolsSummaryArgs>) {
        return this.client.get<WorkerPoolsSummaryResource>(this.requestContext, this.client.getLink("WorkerPoolsSummary"), args);
    }
    sort(order: string[]) {
        return this.client.put(this.requestContext, this.client.getLink("WorkerPoolsSortOrder"), order);
    }
    getSupportedPoolTypes() {
        return this.client.get<WorkerPoolsSupportedTypes>(this.requestContext, this.client.getLink("WorkerPoolsSupportedTypes"));
    }
    async getDynamicWorkerTypes() {
        const result = await this.client.get<DynamicWorkerTypes>(this.requestContext, this.client.getLink("WorkerPoolsDynamicWorkerTypes"));
        return result.WorkerTypes;
    }
}
export type WorkerPoolsMachinesArgs = {
    skip: number;
    take: number;
    partialName: string | undefined;
    isDisabled: boolean | undefined;
    healthStatuses: string | null;
    commStyles: string | null;
    shellNames: string | null;
};
export type WorkerPoolsSummaryArgs = {
    ids: string;
    partialName: string;
    machinePartialName: string | undefined;
    isDisabled: boolean;
    healthStatuses: string;
    commStyles: string;
    hideEmptyWorkerPools: boolean;
    shellNames: string;
};
