import type { Client, RequestContext } from "../client";
import type { DeploymentProcessResource, ModifyDeploymentProcessCommand } from "../resources/deploymentProcessResource";
import type { GitRefResource } from "../resources/index";
type ValidateDeploymentProcessResponse = {
    HasWarnings: boolean;
    TagsWithoutTargetsByStepId: Record<string, string[]>;
    Details: Record<string, string>;
};
type ValidateDeploymentProcessInGitResponse = ValidateDeploymentProcessResponse;
export class ProjectDeploymentProcessRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    protected apiBaseRoute: string = "~/api/{spaceId}";
    protected apiBaseRouteWithProject: string = `${this.apiBaseRoute}/projects/{projectId}{/gitRef}`;
    async get(projectId: string, gitRef?: string | undefined): Promise<DeploymentProcessResource> {
        const route = `${this.apiBaseRouteWithProject}/deploymentprocesses`;
        return await this.client.get<DeploymentProcessResource>(this.requestContext, route, {
            projectId,
            gitRef,
        });
    }
    async modify(deploymentProcess: ModifyDeploymentProcessCommand, projectId: string, gitRef?: string | undefined): Promise<DeploymentProcessResource> {
        const route = `${this.apiBaseRouteWithProject}/deploymentprocesses`;
        const updatedDeploymentProcess = await this.client.put<DeploymentProcessResource>(this.requestContext, route, deploymentProcess, {
            projectId,
            gitRef,
        });
        this.client.dispatchEvent({ type: "DeploymentProcessModified" });
        return updatedDeploymentProcess;
    }
    async validate(gitRef: GitRefResource | undefined, projectId: string, spaceId: string) {
        const params = {
            spaceId,
            projectId,
            gitRef: gitRef?.CanonicalName,
        };
        const route = "~/api/spaces/{spaceId}/projects/{projectId}{/gitRef}/deploymentprocesses/validate";
        if (!gitRef) {
            return this.client.post<ValidateDeploymentProcessResponse>(this.requestContext, route, params, params);
        }
        return this.client.post<ValidateDeploymentProcessInGitResponse>(this.requestContext, route, params, params);
    }
}
