/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { typeSafeHasOwnProperty } from "../utils";
import type { BlueprintResource, ModifyBlueprintCommand } from "./blueprintResource";
import type { ICommitCommand } from "./commitCommand";
import type { DeploymentStepResource } from "./deploymentStepResource";
import { Permission } from "./permission";
import type { ResourceWithId } from "./resource";
import type { ModifyRunbookProcessCommand, RunbookProcessResource } from "./runbookProcessResource";
interface DeploymentProcessResourceLinks {
    Self: string;
    Project: string;
    Template: string;
}
export interface IProcessResource {
    Steps: DeploymentStepResource[];
}
export interface IHaveVersionProcessResource {
    Version: number;
}
export interface DeploymentProcessResource extends IProcessResource, IHaveVersionProcessResource, ResourceWithId<DeploymentProcessResourceLinks> {
    ProjectId: string;
    SpaceId: string;
    LastSnapshotId?: string;
}
export type ModifyDeploymentProcessCommand = DeploymentProcessResource & ICommitCommand;
export type ModifyProcessCommand = ModifyDeploymentProcessCommand | ModifyRunbookProcessCommand | ModifyBlueprintCommand;
export function isDeploymentProcessResource(resource: IProcessResource | null | undefined): resource is NonNullable<DeploymentProcessResource> {
    if (resource === null || resource === undefined) {
        return false;
    }
    const converted = resource as DeploymentProcessResource;
    return !isRunbookProcessResource(resource) && !isBlueprintResource(resource) && converted.Version !== undefined && typeSafeHasOwnProperty(converted, "Version");
}
export function isRunbookProcessResource(resource: IProcessResource | null | undefined): resource is NonNullable<RunbookProcessResource> {
    if (resource === null || resource === undefined) {
        return false;
    }
    const converted = resource as RunbookProcessResource;
    return converted.RunbookId !== undefined && typeSafeHasOwnProperty(converted, "RunbookId");
}
export function isBlueprintResource(resource: IProcessResource | null | undefined): resource is NonNullable<BlueprintResource> {
    if (resource === null || resource === undefined) {
        return false;
    }
    const converted = resource as BlueprintResource;
    return converted.Slug !== undefined && typeSafeHasOwnProperty(converted, "Slug");
}
export function isVersionedProcessResource(resource: IProcessResource | null | undefined): resource is NonNullable<IProcessResource & IHaveVersionProcessResource> {
    const converted = resource as IProcessResource & IHaveVersionProcessResource;
    return converted !== undefined && typeSafeHasOwnProperty(converted, "Version");
}
export function processResourcePermission(resource: IProcessResource): Permission {
    const isRunbook = isRunbookProcessResource(resource);
    return isRunbook ? Permission.RunbookEdit : Permission.ProcessEdit;
}
export type ProcessResource = DeploymentProcessResource | RunbookProcessResource | BlueprintResource;
