import type { Client, RequestContext } from "../client";
import type { PlatformHubConnectionConfigurationResource } from "../resources/platformHubConnectionConfigurationResource";
export class PlatformHubConnectionRepository {
    protected readonly client: Client;
    protected readonly context: RequestContext;
    constructor(client: Client, requestContext: RequestContext) {
        this.client = client;
        this.context = requestContext;
    }
    get() {
        return this.client.get<PlatformHubConnectionConfigurationResource>(this.context, "~/bff/platformhub/version-control");
    }
    modify(connectionResource: PlatformHubConnectionConfigurationResource) {
        return this.client.put<PlatformHubConnectionConfigurationResource>(this.context, "~/bff/platformhub/version-control", connectionResource);
    }
}
